import React from "react";
import Heading from "./heading";

import "../styles/projects.css";
import { PROJECTS } from "../data";

const Projects = () => {
  return (
    <section id="projects">
      <Heading text={"My Projects"} />
      <div className="projects_container">
        {
          PROJECTS.map(project => (
            <div key={project.title} className="projects_card">
              <div className="block">
                <div className="block_left">

                    <div className="d-flex justify-content-between align-items-center">
                    <h2 className="m-0 p-0">{project.title}</h2>
                    {
                      project.link ?
                      <a href={project.link} target="_blank" rel="noreferrer">
                        <img 
                          alt={project.title}
                          src={require("../images/link.png")} 
                          height={18} />
                      </a>
                      : null
                    }
                    </div>
                 
                  <p className="domain_tag">{project.domian}</p>
                  <p className="mb-4 mt-4">
                    <span style={{fontWeight: "600"}}>Tech & Tools: {" "}</span>
                    {project.techStack && project.techStack.join(" | ")}
                  </p>
                  <div className="mb-2">

                  {
                    project.platforms && project.platforms.map((platform)=>(
                      <span 
                        key={platform}
                        className={platform === "Web" ? "webBadge" : "mobileBadge"}>
                        {platform}
                      </span>
                    ))
                  }
                 </div>
                </div>
                <div className="block_right">
                  <p style={{lineHeight: 1.4}}>{project.desc}</p>
                </div>
              </div>
            </div>
          ))
        }
      </div>

    </section>
  )
}

export default Projects;