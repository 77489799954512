import React from "react";
import {JOURNEY} from "../data/index";
import JourneyCard from "./journeyCard";
import Heading from "./heading";

const Journey = () => {
  return (
    <section id="journey">
      <Heading text={"My Journey"} />

      {JOURNEY.map((data)=>{
        return(
          <JourneyCard key={data.title} data={data} />
        )
      })}
  </section>
  )
}

export default Journey;