export const JOURNEY = [
  {
    cat_id: 1,
    type: "EDUCATION",
    title: "Illinois Institute of Technology and Management",
    role: "Master of Science (MS) in Information Technology  |  Fall 2024",
    desc: "Relevant course work: Object Oriented App Development, Full-stack Development, Web Application Foundations, Software testing, Backend Development, Application Methodologies."
  },
  {
    cat_id: 2,
    type: "WORK EXPERIENCE",
    title: "Software Engineer - Uniqubic solutions",
    role: "Gurugram, Haryana, India  |  May 2020 — Nov 2022",
    desc: `I served as a Full Stack Developer at Uniqubic Solutions, where I practiced in full-stack development and implementing performance-rich features. Additionally, I served as a Hybrid Mobile App Developer, contributing to the development of versatile mobile applications. My responsibilities extended to designing API endpoints and executing efficient database queries. In a leadership capacity, I took on the role of Team Lead for two projects. Beyond this, I used to train freshers and interns.`
  },
  {
    cat_id: 2,
    type: "WORK EXPERIENCE",
    title: "Full Stack Developer(Part Time) - Shaurya Sports",
    role: "Mar 2021 — Oct 2022",
    desc: `My role at Shaurya Sports, as a Part-Time Full Stack Developer involved 
    spearheading the development and optimization of both web and mobile 
    applications. Notably, I played a key role in launching a hybrid mobile 
    app that significantly boosted the bookings ratio by 35%. Additionally, 
    I contributed to the front-end development, optimized API responses through 
    local caching with Flask-Caching, and improved user engagement by incorporating 
    in-app chat and community features`
  },
  {
    cat_id: 2,
    type: "WORK EXPERIENCE",
    title: "Full Stack (Part Time) - MyTicketDesk",
    role: "Mar 2022 — Aug 2022",
    desc: `Hired as a Part-Time Full Stack Developer at My Ticket Desk my primary responsibility was to revamp the company's website. In this capacity, I successfully redesigned and improved the website's functionality, leading to a substantial twofold increase in user traffic. Taking ownership of the product development in later months, I introduced new features, including a CMS system, to enhance the overall capabilities of the platform and enabling payment gateways and chat support accross the platfom.`
  },
  {
    cat_id: 2,
    type: "WORK EXPERIENCE",
    title: "Software Engineer - Ferit Cricket Bash",
    role: "Delhi, India  |  Jul 2019 — Apr 2020",
    desc: `At Ferit Cricket Bash, I served as a Frontend Developer, leading the creation of the product using cutting-edge technologies such as React and Redux. My role involved presenting innovative design ideas and translating them into captivating user interfaces. Additionally, I extended my expertise to backend development, contributing significantly to non-relational database queries. This dual-role experience allowed me to seamlessly bridge the gap between frontend and backend functionalities.`
  },
  {
    cat_id: 1,
    type: "EDUCATION",
    title: "Kurukshetra University",
    role: "Bachelor's Degree in Computer Science  |  Spring 2019",
    desc: "Relevant course work: Data Structures, DBMS, Object Oriented Programming, Computer Networks, Web Technology, Mobile App Development, Software Engineering."
  },
  {
    cat_id: 2,
    type: "WORK EXPERIENCE",
    title: "Web Development Intern - MDI Solutions",
    role: "Haryana, India  |  Jun 2018 — Aug 2018",
    desc: `I was an intern frontend developer at MDI Solutions, where I collaborated with an experienced team that facilitated my learning and professional development in the above-mentioned field.`
  },
];

export const SKILLS = [
  {
    name: "JavaScript",
    image: "js.png"
  },
  {
    name: "React Js",
    image: "react.png"
  },
  {
    name: "Node Js",
    image: "node-js.png"
  },
  {
    name: "React Native",
    image: "react.png"
  },
  {
    name: "Java",
    image: "java.png"
  },
  {
    name: "Mongo DB",
    image: "server.png"
  },
  {
    name: "SQL",
    image: "sql.png"
  },
  {
    name: "AWS",
    image: "aws.png"
  },
  {
    name: "Data Structures",
    image: "ds.png"
  },
  {
    name: "HTML",
    image: "html.png"
  },
  {
    name: "CSS",
    image: "css-3.png"
  },
  {
    name: "Bootstrap",
    image: "bootstrap.png"
  }

]

export const PROJECTS = [
  {
    title: "Shaurya Sports",
    domian: "Sports",
    desc: `Shaurya Sports is an online sports community platform with the primary goal of 
          discovering young talent nationwide. By offering substantial opportunities, it 
          provides aspiring athletes with a chance to showcase their skills on a national 
          platform. Specializing in cricket, the platform organizes competitions across various 
          states, allowing participants to vie for a significant prize in progressive stages, 
          mirroring the structure of other sports tournaments. Shaurya Sports boasts a dedicated 
          landing website, a distinct application enabling players to monitor their performances 
          and other relevant information, and a mobile Android application for convenient access.
    `,
    platforms: ["Web", "Mobile"],
    techStack: ["React", "Redux", "React Native", "HTML/CSS/JS", "Node Js", "MongoDB", "Bulma", "React-Native-Elements"],
    link: "https://www.shauryasports.com"
  },
  {
    title: "MyTicketDesk",
    domian: "Tickets Booking",
    desc: "Myticketdesk is an online travel reservation platform dedicated to both domestic and global travel, recognized for its principles, commitment, and passion for customer service. Their highly skilled travel guides are capable of providing travelers with some of the finest travel options. Additionally, they can customize luxurious travel experiences to allow individuals to explore their unique world in their own way.",
    platforms: ["Web"],
    techStack: ["Python", "Jinja", "Flask", "Javascript", "MongoDB", "Bootstrap"],
    link: "https://www.myticketdesk.com"
  },
  {
    title: "Craqit",
    domian: "Education",
    desc: "Craqit is an online platform democratizing the arts. Dedicated to supporting artists, it provides education, curated spaces, and collaboration opportunities. With an invitation-only onboarding, Craqit connects serious art lovers with creators, fostering an ecosystem that addresses artistic hurdles. Whether a learner, artist, or community member, Craqit is your companion for exploring, creating, and earning in the world of arts.",
    platforms: ["Web", "Mobile"],
    techStack: ["React", "Redux", "React Native", "HTML/CSS/JS", "Node Js", "MongoDB", "AntDesign"],
    link: "https://www.craqit.com"
  },
  {
    title: "AkarIndia",
    domian: "e-commerce",
    desc: "Akar India stands out as a premier B2B e-commerce platform, specializing in online wholesale business across diverse product categories. Tailored for businesses, it boasts an extensive database of products and customizable services. Formerly recognized as one of the top business e-commerce websites in India, Akar India continues to provide a sophisticated and comprehensive platform for wholesale transactions.",
    platforms: ["Mobile"],
    techStack: ["React Native", "Redux", "Javascript", "React-Navigation", "React-Native-Elements"], 
  },
  {
    title: "Ferit Cricket Bash",
    domian: "Sports",
    desc: "Ferit Cricket Bash is an independent amateur cricket league for the passionate cricketers. Based purely on merit, bound by no age limits, cricketers above 15 yrs of age can participate in trials to showcase their skills.",
    platforms: ["Web"],
    techStack: ["React", "Redux", "Javascript", "HTML/CSS/JS"], 
  }
]