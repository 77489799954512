import React from "react";
import "../styles/navbar.css";

const Intro = () => {
  return (
    <nav>
      <div id="nav_container">
        <h1 id="logo">AkashGoindi</h1>
        <ul id="nav_links">
          <li className="nav_link">Intro</li>
          <li className="nav_link"><a href="#about">About Me</a></li>
          <li className="nav_link"><a href="#journey">Journey</a></li>
          <li className="nav_link"><a href="#projects">Projects</a></li>
        </ul>
      </div>
    </nav>
  )
}

export default Intro;