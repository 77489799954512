import React from "react";
import "../styles/journey.css";

const JourneyCard = ({data: {cat_id, type, title, role, desc= ""}}) => {
  return(
    <div className="journey_card">
      <div className="journey_card-left">
        <div className="img_block">
          <img 
            alt={`${title}_image`}
            src={require(cat_id === 1 ? "../images/education.png" : "../images/portfolio.png")} 
          />
        </div>
      </div>
      <div className="journey_card-right">
        <p className="jrny_type">{type}</p>
        <p className="jrny_title">{title}</p>
        <p className="jrny_role">{role}</p>
        {
          desc && <p style={{textAlign: "justify"}} className="jrny_desc">{desc}</p>
        }
      </div>
    </div>
  )
}

export default JourneyCard;