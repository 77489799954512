import React from "react";
import "../styles/about.css";
import Heading from "./heading";
import { SKILLS } from "../data";

const About = () => {
  return(
    <section id="about" className="mt-4">
    <Heading text={"About Me"} />
    <p className="paragraph">As a frontend developer with over 4 years of experience, I have honed my skills in technologies such as JavaScript, React, and React Native. As a team lead, I have successfully managed projects and motivated teams to deliver high-quality work on time and within budget. Currently pursuing an MS in Information Technology and Management, I am passionate about leveraging my technical expertise and leadership skills to drive innovation and achieve business objectives. I thrive in fast-paced environments where I can apply my problem-solving abilities to overcome challenges and deliver outstanding results.</p>
    <br />

    <h3>Hands-on Experience:</h3>

    <div className="skills-container">
      {
        SKILLS.map((data)=>(
          <div key={data.name} className="skills-item">
            <img src={require(`../images/skills/${data.image}`)} alt={data.image}/>
            <p>{data.name}</p>
          </div>
        ))
      }
    </div>
  
  </section>
  )
}

export default About;