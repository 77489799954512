import React from "react";
import "../styles/intro.css";

const resume = "https://drive.google.com/file/d/1vtHC6X7At4K1D_xtgB45wBBhurJV4lCq/view?usp=sharing";
const linkedin = "https://www.linkedin.com/in/akash-goindi/";
const github = "https://github.com/AkashGoindi";

const Intro = () => {

  const downloadResume = (link) => {
    const newWindow = window.open(link, '_blank');
    if (newWindow) {
      newWindow.opener = null; // nullify the reference to the current window in the new window
    }
  }

  return (
    <section id="into_container">
      <div id="intro">
        <img id="smallMe" src={require("../images/me.jpg")} alt="mySmallLogo" />
        <h1 className="welcome_text">Hi, I am</h1>
        <h1 className="welcome_text clr_prim"> Akash Goindi</h1>

        {/* <div className="primaryBtn resumeBtn">
          <div id="translate"></div>
          <a href="#">Download Resume</a>
        </div> */}

        <div onClick={() => downloadResume(resume)} className="primaryBtn resumeBtnWithIcon">
          <div id="dub-arrow"><img src={require("../images/download.png")} alt="downloadIcon" /></div>
          <a href="#">Download Resume</a>
        </div>

        <div className="d-flex">
          <img
            alt="linkedinLogo"
            className="profile_logo mr-3 ml-3"
            src={require("../images/linkedin.png")}
            onClick={() => downloadResume(linkedin)}
            height={48} />
            <img
              alt="githubLogo"
              className="profile_logo mr-3 ml-3"
              src={require("../images/github.png")}
              onClick={() => downloadResume(github)}
              height={48} />
        </div>
      </div>
    </section>

  )
}

export default Intro;