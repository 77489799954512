import React from 'react';
import Navbar from './components/navbar';
import Intro from './components/intro';
import About from './components/about';
import Journey from './components/journey';
import Projects from './components/projects';
import Footer from './components/footer';

const Home = () => {
  return (
    <>
      <Navbar />
      <Intro />
      <div className='container' style={{padding: "0px 15px"}}>
        <About />
        <Journey />
        <Projects />
      </div>
      <Footer />
    </>
  )
}

export default Home;