import React from "react";
import "../styles/footer.css";

const Footer = () => {
  return(
    <footer>
      <p>{new Date().getFullYear()} @Akash Goindi | All Right Reserved</p>
    </footer>
  )
}

export default Footer;